import React from 'react'
import ComingSoon from '../components/ComingSoon'
import { paper5 } from '../assets'
const Waw = () => {
  return (
    <div className='bg-[#ffffff]'>
      <ComingSoon image={paper5} soon="At work!!!"/>
    </div>
  )
}

export default Waw
